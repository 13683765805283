
/*LOADING*/

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;

}

.loader {
  max-width: 15rem;
  width: 100px;
  height: auto;
  stroke-linecap: round;
}

circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
          animation-name: preloader;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
          transform-origin: 170px 170px;
  will-change: transform;
}
circle:nth-of-type(1) {
  stroke-dasharray: 550;
}
circle:nth-of-type(2) {
  stroke-dasharray: 500;
}
circle:nth-of-type(3) {
  stroke-dasharray: 450;
}
circle:nth-of-type(4) {
  stroke-dasharray: 300;
}
circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
  -moz-animation-delay:  -0.45s;
          animation-delay: -0.45s;
}
circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




/*LOADING*/

.App-link {
  color: #09d3ac;
}

.form-check-label{
  cursor: pointer;
}
.items {
  margin-bottom: 1px;
  padding: 10px;
   
  width: 95%;
  margin-left: 10px;
}

.placeholder-preview {
  border: 3px dashed #c3c3c3;
  text-align: center;
  background-color: rgba(2, 1, 1, 0.1);
  cursor: pointer;
  min-height: 200px;
}

.empty-preview {
  text-align: center;
  min-height: 200px;
  padding-top: 80px;
}

.error-file {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.list-data {
  max-height: 600px;
  min-height: 400px;
  overflow: auto;
}

.leaflet-draw-toolbar a {
  background-image: url('https://unpkg.com/leaflet-draw@1.0.2/dist/images/spritesheet.png');
  background-repeat: no-repeat;
  color: transparent !important;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}

.form-control-borderless {
  border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.modal {
  color: #000;
}

.header {
  background-color: rgba(2, 1, 1, 0.668);
  padding: 10px;
  margin-left: 68px;
  margin-top: 10px;
  border-radius: 5px;
}

.config {
  float: right;
  ;
}

.actionHeader {
  width: 150px;
}

.rbt-close {
  margin-top: -30px;
  margin-right: 10px;
}

.rbt-token-remove-button {
  margin-top: -0px;
  margin-right: 10px;
}

.some-margin {
  visibility: hidden;
  height: 0px;
}

.blockMap {
  background-color: rgba(2, 1, 1, 0.668);
  z-index: 999999999;
  pointer-events: none;
}

label {
  font-weight: 600
}

.tagInput {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  padding: 5px;
}

.react-tagsinput-custom {
  width: auto;
  border: 0px;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  
}

.react-tagsinput-tag-custom{
  background-color: #eaeff5;
    border-radius: 2px;
    border: 1px solid #20a8d8;
    color: #20a8d8;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}


.container-avatar {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.avatar-profile {
  opacity: 1;
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle-avatar {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-avatar:hover .avatar-profile {
  opacity: 0.3;
}

.container-avatar:hover .middle-avatar {
  opacity: 1;
}


.footer.content > img,
.footer.content > p {
    display: inline-block;
}

.headerWidth{
  min-width:  100px;
}
