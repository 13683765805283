// Here you can add other styles



.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
    margin: 10px 6.5px;
}
 .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}
 
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.react-bootstrap-table th.sortable {
  cursor: pointer; }

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc; }

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc; }

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px; }

.react-bootstrap-table th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4; }

.react-bootstrap-table th .order-4:after {
  content: "\2193";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191"; }

.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4; }

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193"; }

.react-bootstrap-table th[data-row-selection] {
    width: 40px;
}


.react-bootstrap-table table{
    table-layout: auto
}